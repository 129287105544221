import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import "./customProductsTemplate.scss";

const CustomProductsTemplate = () => {
  const data = useStaticQuery(graphql`
		query QUERY_CUSTOM_PRODUCTS {
			allContentfulPaginaProductosPersonalizados {
        nodes {
          buttonHref
          buttonText
          description1 {
            raw
          }
          description2 {
            raw
          }
          products {
            productName
            productImage {
              file {
                url
              }
            }
            href
          }
          solutionsText
          subtitle
          textTitle
          solutions {
            titleText
            images {
              file {
                fileName
                url
              }
            }
          }
        }
      }
		}
  `);

  const {
    buttonHref,
    buttonText,
    description1,
    description2,
    products,
    solutions,
    solutionsText,
    subtitle,
    textTitle
  } = data.allContentfulPaginaProductosPersonalizados.nodes[0];

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span>{text}</span>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <p className="t-custom-products__desc">{children}</p>
      }
    }
  }

  return (
    <section className="t-custom-products">
      <h1 className="t-custom-products__title">{textTitle}</h1>
      {documentToReactComponents(JSON.parse(description1.raw), options)}
      <div className="t-custom-products__products">
        {products.map((product) => (
          <div
            key={product.productName}
            className="t-custom-products__products__product"
          >
            <div className="t-custom-products__products__product__image">
              <img
                src={product.productImage.file.url}
                alt={product.productName}
              />
            </div>
            <a
              href={product.href}
              className="t-custom-products__products__product__name"
            >
              {product.productName}
            </a>
          </div>
        ))}
      </div>
      <h2 className="t-custom-products__subtitle">
        {subtitle}
      </h2>
      {documentToReactComponents(JSON.parse(description2.raw), options)}
      <a
        href={buttonHref}
        className="t-custom-products__button"
      >
        {buttonText}
      </a>
      <h2 className="t-custom-products__subtitle">
        {solutionsText}
      </h2>
      <div className="t-custom-products__solutions">
        {solutions.map((solution) => (
          <div key={solution.titleText} className="t-custom-products__solutions__solution">
            <div className="t-custom-products__solutions__solution__images">
              {solution.images.map((image) => (
                <img
                  key={image.file.fileName}
                  src={image.file.url}
                  alt={image.file.fileName}
                />
              ))}
            </div>
            <p className="t-custom-products__solutions__solution__name">
              {solution.titleText}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default CustomProductsTemplate;