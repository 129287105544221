import React from "react";
import Layout from "../components/organisms/layout/layout";
import CustomProductsTemplate from "../components/templates/custom-products-template/customProductsTemplate";

const CustomProductsPage = () => {
  return (
    <Layout title="Dicar | Productos personalizados">
      <CustomProductsTemplate />
    </Layout>
  )
}

export default CustomProductsPage;